<template>
  <div class="box">
    <div class="tabbar">
      <!-- <div :class="index == 0 ? 'active' : ''" @click="index = 0">验证码登录</div> -->
      <div :class="index == 1 ? 'active' : ''" @click="index = 1">密码登录</div>
    </div>
    <!-- <div v-if="index == 0">
      <div class="lines_bottom">
        <van-field v-model="phone" type="tel" placeholder="请输入手机号" />
      </div>
      <div class="lines_bottom">
        <van-field v-model="sms" center clearable placeholder="验证码">
          <template #button>
            <van-button size="small" plain type="default" @click="getsms">{{ times ? number : '获取验证码' }}
            </van-button>
          </template>
        </van-field>
      </div>
      <div class="bottom">
        <van-button class="m_bottom2" type="success" round size="large" @click="login">登录</van-button>
      </div>
    </div> -->
    <div v-if="index == 1">
      <div class="lines_bottom">
        <van-field v-model="te1" type="tel" placeholder="请输入手机号" />
      </div>
      <div class="lines_bottom">
        <van-field v-model="te2" type="password" placeholder="请输入密码" />
      </div>
      <div class="bottom">
        <van-button class="m_bottom2" type="success" round size="large" @click="login2">登录</van-button>
      </div>
    </div>
    <van-popup v-model:show="show" :close-on-click-overlay="true">
      <div class="tipsbox">
        <p class="p1">图形验证码</p>
        <div><img :src="config.fileServerUrl + captcha.captchaUrl" alt=""></div>
        <van-field v-model="tutex" type="text" placeholder="请输图形验证码" />
        <van-button type="success" round size="small" @click="getsmstow">确认</van-button>
      </div>
    </van-popup>
    <Mask v-if="marke"></Mask>
  </div>
</template>
<script>
import md5 from 'js-md5';
// import { wxqq } from '../utils/utils'
import Mask from '../components/Mask'
import { Toast } from 'vant';

export default {
  name: 'Login',
  data() {
    return {
      show: false,
      phone: '',
      sms: '',
      te1: '',
      te2: '',
      tutex: '',
      config: {
        fileServerUrl: ''
      },
      captcha: {
        captchaId: '',
        captchaUrl: ''
      },
      index: 1,
      times: false,
      marke: false,
      number: 60,
    }
  },
  components: {
    Mask,
  },
  mounted() {
    this.getConfig()
    // this.marke = wxqq()
  },
  methods: {
    getConfig() {
      this.$request.post('/api/base/config').then((res) => {
        if (res.data.code == 0) {
          this.config = res.data.data
          localStorage.setItem('config', JSON.stringify(res.data.data))
        }
      })
    },
    login() {
      let data = {
        phone: this.phone,
        code: this.sms
      }
      this.$request.post('/api/user/passport/loginByPhoneCode', data).then((res) => {
        if (res.data.code == 0) {
          localStorage.setItem('token', res.data.data.token)
          this.$request.postJson('/api/user/pay_group/info', {}).then((res) => {
            if (res.data.code == 0) {
              if (res.data.data) {
                this.$router.push({ path: '/' })
              } else {
                Toast('未查询到推广组信息')
              }
            }
          })
        }
      })
    },
    login2() {
      let data = {
        phone: this.te1,
        password: md5(this.te2)
      }
    
      localStorage.setItem("gphone",data.phone)
      this.$request.post('/api/user/passport/loginByPhone', data).then((res) => {
        if (res.data.code == 0) {
          localStorage.setItem('token', res.data.data.token)
          this.$request.postJson('/api/user/pay_group/info', {}).then((res) => {
            if (res.data.code == 0) {
              if (res.data.data) {
                this.$router.push({ path: '/' })
              } else {
                Toast('未查询到推广组信息')
              }
            }
          })
        }
      })
    },
    getsms() {
      if (this.times) return
      let _this = this
      this.$request.post('/api/base/verify', {
        phone: this.phone
      }).then((res) => {
        if (res.data.code == 6) {
          // 图形验证码
          _this.$request.post('/api/base/captcha').then((data) => {
            _this.show = true
            _this.captcha = data.data.data
          })
        } else if (res.data.code == 0) {
          _this.timeNumber()
        }
      })
    },
    getsmstow() {
      let _this = this
      // 带图形验证码获取验证码
      this.$request.post('/api/base/verify', {
        phone: this.phone,
        captchaId: this.captcha.captchaId,
        captchaCode: this.tutex
      }).then((res) => {
        if (res.data.code === 0) {
          _this.show = false
          _this.timeNumber()
        }
      })
    },
    timeNumber() {
      this.times = true
      this.number = 60
      let timer = setInterval(() => {
        this.number -= 1
        if (this.number <= 0) {
          clearInterval(timer)
          this.times = false
        }
      }, 1000)
    }
  }
}
</script>
<style lang="less" scoped>
.box {
  overflow: hidden;
}

.lines_bottom {
  height: 52px;
}

.van-field {
  height: 52px;
}

.bottom {
  width: 100%;
  padding: 0 20px;
  /*position: fixed;*/
  /*bottom: 0;*/
  /*left: 0;*/
  margin-top: 30px;
  box-sizing: border-box;
}

.tipsbox {
  width: 200px;
  height: auto;
  padding: 20px;
  text-align: center;

  .p1 {
    font-size: 16px;
    margin-bottom: 20px;
  }

  img {
    width: 100px;
    height: 50px;
  }

  button {
    width: 100px;
    margin-top: 20px;
  }
}

.tabbar {
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  width: 80%;
  height: 40px;
  line-height: 40px;
  margin: 30px auto;
  display: flex;

  div {
    width: 50%;
    height: 100%;
    font-size: 16px;
    text-align: center;
    border-bottom: 2px solid #eee;
  }

  .active {
    border-bottom: 2px solid #879fee;
  }
}
</style>
